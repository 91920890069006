<!-- 托管组织排名 -->
<template>
  <LandCard title="托管组织排名" :isLeftMore="false">
    <template>
      <div class="innerBox">
        <el-select class="selsectbox" v-model="yearValue" placeholder="请选择" size="small" @change="handleValueChange">
          <el-option v-for="item in yearOptions" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
        <RankCard class="rankCardStyle" :list="data" :barColor="itemColor" />
      </div>

    </template>
  </LandCard>
</template>

<script>
  import LandCard from "@/components/Card/landTrustCard.vue";
  import RankCard from "@/components/echart/CategoryRankChart.vue";
  import { getTGZZInfo } from "@/api/landTrustStatistics.js";
  export default {
    components: {
      LandCard,
      RankCard,
    },
    data() {
      return {
        yearValue: 2022,
        itemColor: "#67C23A",
        yearOptions: [],
        data: [],
      };
    },
    mounted() {
      this.init();
    },
    methods: {
      selectYear(n, m) {
        if (m === "" || m === undefined) m = 0;
        var myDate = new Date();
        var startYear = myDate.getFullYear() - n; //起始年份
        var endYear = myDate.getFullYear() + m; //结束年份
        var arr = new Array();
        for (var i = startYear; i <= endYear; i++) {
          var obj = { value: i, name: i + "年" };
          arr.push(obj);
        }
        return arr;
      },
      init() {
        this.yearOptions = this.selectYear(3);
        this.yearValue = this.yearOptions[3].value;
        this.getTJInfo();
      },
      async getTJInfo() {
        let params = {
          year: this.yearValue
        };
        let res = await getTGZZInfo(params);
        if (!res || res.code != 0) {
          return;
        }
        let arr2 = res.data.slice(0, 4);
        arr2.forEach((item) => {
          item.name = item.companyName;
          item.value = item.companyArea;
        });
        console.log(arr2)
        this.data = arr2;
      },
      handleValueChange(val) {
        this.getTJInfo();
      },
    },
  };
</script>

<style lang="less" scoped>
  .innerBox {
    width: 100%;
    height: 100%;
    position: relative;

    .rankCardStyle {
      width: 100%;
      /* height: calc(100% - 150px); */

      /* height: 376px */
      height: 90%;
    }

    .selsectbox {
      position: absolute;
      top: -10%;
      left: 75%;

      width: 130px;

      /deep/ .el-input__inner {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
    }
  }
</style>