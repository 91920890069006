<template>
  <card title="土地托管统计">
    <div class="landSta">
      <div class="landSta-top">
        <TopLeft />
        <TopRight />
      </div>

      <div class="landSta-bottom">
        <BottomLeft />
        <BottomCenter />
        <BottomRight />
      </div>
    </div>
  </card>
</template>

<script>
  import Card from "@/components/Card/index.vue";
  import TopLeft from "./landTrustSubpage/topLeft.vue";
  import TopRight from "./landTrustSubpage/topRight.vue";
  import BottomLeft from './landTrustSubpage/bottomLeft.vue';
  import BottomCenter from './landTrustSubpage/bottomCenter.vue';
  import BottomRight from './landTrustSubpage/bottomRight.vue';
  export default {
    components: {
      Card,
      TopLeft,
      TopRight,
      BottomLeft,
      BottomCenter,
      BottomRight
    },
  };
</script>

<style lang="less" scoped>
  /* .landSta {
    height: 900px;
    display: grid;

    gap: 16px;

    &-top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    &-bottom {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  } */

  .landSta {
    height: 726px;
    display: grid;
    grid-template-rows: 376px 332px;
    gap: 16px;
    box-sizing: border-box;

    &-top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }

    &-bottom {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      box-sizing: border-box;
    }
  }

  /* .landSta {
    height: 724px;
    display: grid;

    grid-template-rows: minmax(0, 376px) minmax(0, 332px);
    gap: 16px;

    &-top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      min-height: 0;
    }

    &-bottom {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      min-height: 0;
    }
  }


  [class^="landSta-"]>* {
    overflow: auto;
    min-width: 0;
    min-height: 0;
  } */
</style>
